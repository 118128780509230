body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

.react-google-flight-datepicker .day.selected {
  color: white !important;
}

code {
  font-family: "Nunito", sans-serif;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiTableSortLabel-icon {
  opacity: 0;
  font-size: 15px !important;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-left: 4px;
  user-select: none;
  margin-right: 4px;
  fill: #000 !important;
}

.MuiTableSortLabel-iconDirectionAsc {
  fill: #000 !important;
}

/* .MuiSvgIcon-root{
  fill:black !important;
} */

.backer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.closeIcnnew {
  position: absolute;
  right: 13px;
  top: 0px;
  background: #00afb9;
  border: none;
  outline: none;
  padding: 12px;
  width: 130px;
  color: #fff;
  border-radius: 10px;
  margin-top: 15px;
  font-size: 12px;
  cursor: pointer;
  margin-right: 2em;
}

.viewP {
  cursor: pointer;
  color: #00afb9;
  font-size: 18px;
}
input {
  font-family: inherit !important;
}
button {
  font-family: inherit !important;
}
/* datepicker */

.imageHolders {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.imageHolders img {
  cursor: pointer;
}
.imageHolders > div:nth-child(1) {
  margin-right: 0.7em;
}

/* .closeIcnnew{
  position: absolute;
  right: 13px;
  width: 38px;
  top: 10px;
  cursor: pointer;
} */

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}
select {
  color: black !important;
}
.SplitPane {
  min-height: 90vh !important;
  height: auto !important;
  position: unset !important;
}
input:disabled {
  background: white !important;
}

.loaderAdj {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */

  color: #d3d7d8;
  opacity: 1; /* Firefox */
  font-family: "Nunito", sans-serif !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d3d7d8;
  font-family: "Nunito", sans-serif !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #d3d7d8;
  font-family: "Nunito", sans-serif !important;
}

select {
  font-family: "Nunito", sans-serif !important;
  font-size: 16px;
}

.MuiInputLabel-formControl {
  color: #d3d7d8 !important;
  font-weight: unset !important;
  font-family: "Nunito", sans-serif !important;
  font-size: 16px !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #00afb9 !important;
}

.MuiSvgIcon-root {
  /* fill: #00AFB9 !important; */
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid #d3d7d8 !important;
  pointer-events: none;
}

.MuiTypography-subtitle1 {
  font-size: 1.5rem !important;
  font-family: "Nunito", sans-serif !important;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.00938em;
}

.MuiCircularProgress-colorPrimary {
  color: #00afb9 !important;
}

.showSuccessmsg {
  color: #0eda47;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-top: 1em;
}

button:focus {
  border: 1px solid black;
}
._36Zc1___container-StyledContainer__cls1 {
  min-height: 100vh !important;
  height: 100vh !important;
}
