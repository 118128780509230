.container {
  padding: 2em;

  width: 80%;
  margin: 0 auto;
  margin-top: 2em;
}

header h1 {
  font-size: 22px;
  font-weight: 500;
}

.loginform {
  padding-top: 2em;
  padding-bottom: 2em;
}

.labelStyle {
  color: #02afb9;
}
.MuiFormControl-root {
  padding-top: 11px !important;
}
.daterange {
  width: 42%;
  margin-top: 5px;
  height: 45px;
}
.react-calendar {
  font-family: "Nunito", sans-serif !important;

  border: 1px solid #d3d7d8 !important;
}
.react-calendar__navigation {
  height: 11px !important;
}
.react-daterange-picker__wrapper {
  border-bottom: 1px solid #d3d7d8 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}
.react-date-picker__inputGroup__divider {
  color: #d3d7d8 !important;
}
.react-daterange-picker__range-divider {
  margin-right: 60px !important;
}
.textDate {
  font-size: 16px;
  padding: 10px;
  outline: none;
  padding-top: 30px;
  width: 91%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #02afb9;
  text-transform: uppercase;
}
.searchTextInput {
  font-size: 16px;
  padding: 5px;
  outline: none;
  padding-top: 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d3d7d8;

  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.searchTextInput:focus {
  border-bottom: 2px solid #02afb9;
}
.btnDiv {
  padding-top: 2em;
  padding-bottom: 2em;
}
.centerAdj {
  display: flex;
  justify-content: center;
}
.subBtn {
  background: #f07167;
  border: none;
  outline: none;
  padding: 12px;
  width: 27%;
  color: white;
  border-radius: 10px;
  margin-top: 55px;
  font-size: 16px;
  cursor: pointer;
}
.PatientSelect {
  /* color: black; */
  padding: 9px 0px !important;
  outline: none;
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d3d7d8;

  font-size: 16px;
}
.NextBtn1 {
  background: #f07167;
  border: none;
  outline: none;
  padding: 12px;
  width: 20%;
  color: white;
  border-radius: 10px;
  margin-top: 50px;
  /* margin-bottom: 40px; */
  font-size: 15px;
  cursor: pointer;
}
/* Mobile */
@media only screen and (max-width: 600px) {
  .inputDiv {
    width: 80% !important;
  }
  .loginBG {
    background-image: none !important;
    background-size: cover;
  }
  .mobformat {
    margin-left: 25% !important;
  }
  .footeerlogin {
    margin-top: 25px !important;
  }
  .welcome h3 {
    font-size: 27px !important;
  }
  .welcome h4 {
    font-size: 30px !important;
  }
}
/* Tab */

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .mobformat {
    margin-left: 25% !important;
  }
  .inputDiv {
    width: 50% !important;
  }
}

abbr[title] {
  text-decoration: unset !important;
  color: #d5d5d5 !important;
  font-weight: bold;
}

.react-daterange-picker {
  display: inline-flex;
  position: relative;

  font-family: inherit !important;
}

.react-daterange-picker,
.react-daterange-picker *,
.react-daterange-picker *:before,
.react-daterange-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-daterange-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}
.react-daterange-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  border: thin solid gray;
}
.react-daterange-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  height: 100%;
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}
.react-daterange-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-daterange-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}
.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-daterange-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-daterange-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-daterange-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}
.react-daterange-picker__button:enabled {
  cursor: pointer;
}
.react-daterange-picker__button:enabled:hover
  .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus
  .react-daterange-picker__button__icon {
  stroke: #4486f5;
}
.react-daterange-picker__button:disabled .react-daterange-picker__button__icon {
  stroke: #6d6d6d;
}
.react-daterange-picker__button svg {
  display: inherit;
}
.react-daterange-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
.react-daterange-picker__calendar--closed {
  display: none;
}
.react-daterange-picker__calendar .react-calendar {
  border-width: thin;
  padding: 0.5em !important;
}

.react-calendar__navigation button {
  min-width: unset !important;
  font-size: 16px !important;
}

/* .react-daterange-picker *{
      color:#4C5053 ;
  } */

button {
  color: unset;
}

.react-calendar__tile:enabled:hover {
  background-color: unset !important ;
  border: 1px solid #4486f5 !important;
  /* margin:0.2em !important; */
}

/* .react-calendar__tile:enabled:focus {
    background-color: unset !important ;
    border:1px solid #4486F5 !important;
    
} */

.react-calendar__tile--active {
  background: #4486f5 !important;
  color: #fff !important;
}

.react-calendar__navigation__label__labelText--from {
  font-size: 16px !important;
  margin: 0.2em 0;
}

.react-daterange-picker__inputGroup__input:invalid {
  background: unset !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: unset !important;
}

.react-calendar button {
  color: #4c5053;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 1em !important;
  background: none;
  /* border-radius: 50%  !important; */
  margin: 0.4em;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
  background: #4486f5 !important;
  color: #fff !important;
}

/* .react-calendar__tile--now {
  background: inherit !important;
  color: inherit !important;
  border:unset !important;
} */

.react-calendar__navigation button {
  height: 25px;
}

/* .react-calendar__tile--now{
  background: #4486F5 !important;
  border:1px solid #4486F5 !important;
  color:#fff !important;
} */

/* .react-calendar--selectRange .react-calendar__tile--hover{
  background: rgba(16,135,255,0.3) !important;
  
} */

/* .react-calendar__tile--now{
  background: unset !important ;
}

.react-calendar__tile--now, .react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus{
  background: unset !important;
 
  color:unset !important;
} */

.DatePicker {
  width: 100%;
}
.DatePicker__input {
  border: none !important;
  border-bottom: 1px solid #d3d7d8 !important;
  width: 100%;
  padding: 15px 2px 10px !important;
  font-size: 15px !important;
  color: black !important;
  text-align: left !important;
  /* text-transform: capitalize !important; */
}
.DatePicker__input:focus-visible {
  border-bottom: 2px solid #00afb9 !important;
  /* border-bottom: none !important; */
  /* outline: 1px solid #00afb9 !important; */
  outline: none !important;
}

.DatePicker__input::placeholder {
  color: gray !important;
  text-align: left;
  margin-left: 0 !important;
}
